import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { GridComponent } from './grid/grid.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { ToastModule } from 'primeng/toast';
import { BadgeModule } from 'primeng/badge';
import { LogoComponent } from './logo/logo.component';
import { CheckboxModule } from 'primeng/checkbox';
import { GridHeaderComponent } from './grid-header/grid-header.component';
import { StepsModule } from 'primeng/steps';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { FileUploadModule } from 'primeng/fileupload';
import { AcknowledgeModalComponent } from './success-modal/acknowledge-modal.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToolbarModule } from 'primeng/toolbar';
import { CardModule } from 'primeng/card';
import { VersionPipe } from './pipes/version.pipe';
import { SplitterModule } from 'primeng/splitter';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    GridComponent,
    GridHeaderComponent,
    LogoComponent,
    ConfirmationModalComponent,
    AcknowledgeModalComponent,
    VersionPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    DialogModule,
    TagModule,
    ToastModule,
    MenubarModule,
    AvatarModule,
    BadgeModule,
    MenuModule,
    CheckboxModule,
    DatePipe,
    StepsModule,
    DividerModule,
    InputSwitchModule,
    RadioButtonModule,
    ProgressBarModule,
    FileUploadModule,
    OverlayPanelModule,
    ToolbarModule,
    CardModule,
    SplitterModule,
    TooltipModule,
  ],
  exports: [
    GridComponent,
    GridHeaderComponent,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    DialogModule,
    TagModule,
    ToastModule,
    MenubarModule,
    AvatarModule,
    BadgeModule,
    MenuModule,
    CheckboxModule,
    DatePipe,
    StepsModule,
    DividerModule,
    InputSwitchModule,
    RadioButtonModule,
    ProgressBarModule,
    ConfirmationModalComponent,
    FileUploadModule,
    LogoComponent,
    AcknowledgeModalComponent,
    OverlayPanelModule,
    ProgressSpinnerModule,
    ToolbarModule,
    CardModule,
    SplitterModule,
    TooltipModule,
  ],
  providers: [DatePipe, VersionPipe],
})
export class SharedModule {}
