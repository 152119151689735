import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'version'
})
export class VersionPipe implements PipeTransform {

  transform(value: string): string {
    const regex = /\d+\.\d+\.\d+/;
    const match = regex.exec(value);
    return match ? match[0] : '';
  }

}
