const configUpgradeParser = () => { };

configUpgradeParser.parse = (legacyReport) => {
  try {
    legacyReport = JSON.parse(legacyReport);
  } catch (e) {
    return 'invalid json';
  }
  const report = {};
  report.added = generateFormattedItems(legacyReport, 'added');
  report.removed = generateFormattedItems(legacyReport, 'objectsToRemove');
  report.updated = generateFormattedItems(legacyReport, 'diffs');
  return JSON.stringify(report);
};

const excludedProperties = ['uid', 'instance', 'instanceLink', 'organizationUnitLink', 'code'];

const generateFormattedItems = (legacyReport, itemsLegacyProperty) => {
  const items = legacyReport[itemsLegacyProperty] && Array.isArray(legacyReport[itemsLegacyProperty]) ?
    legacyReport[itemsLegacyProperty] : [];

  const formattedItems = items.map((item) => {
    const rootKey = Object.keys(item)[0];
    const path = buildNestedObjectPath(item, rootKey);

    const additionalKeys = {};

    for (let i = 1; i < Object.keys(item).length; i++) {
      const key = Object.keys(item)[i];
      const value = item[key];
      const clonedValue = JSON.parse(JSON.stringify(value));
      removeUids(clonedValue);
      additionalKeys[key] = clonedValue;
    }

    return { path, ...additionalKeys };
  });

  return formattedItems;
};

const removeUids = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === 'uid') {
          delete obj[key];
        }
        else if (typeof obj[key] === 'object') {
          removeUids(obj[key]);
        }
      }
    }
  }
};

const buildNestedObjectPath = (object, key) => {
  const nestedObject = object[key];
  let path = buildObjectPath(object, key);
  for (const nestedKey in nestedObject) {
    if (Object.hasOwn(nestedObject, nestedKey)) {
      const propNameLowerCase = nestedKey.toLowerCase();
      if (!excludedProperties.includes(nestedKey) && !propNameLowerCase.includes('type')) {
        if (typeof nestedObject[nestedKey] !== 'object') {
          // path += concatObjectPath(buildObjectPath(nestedObject, nestedKey));
        } else {
          path += concatObjectPath(buildNestedObjectPath(nestedObject, nestedKey));
        }
      }
    }
  }
  return path;
};


const buildObjectPath = (parentObject, key) => {
  const object = parentObject[key];
  if (parentObject.uid) {
    return '->';
  }
  if (object.uid) {
    return `${key}${concatObjectTypeIfAny(object)}${concatObjectCodeIfAny(object)}`;
  }
  return '';
};

const concatObjectTypeIfAny = (object) => {
  if (object !== undefined) {
    for (const prop in object) {
      if (Object.hasOwn(object, prop)) {
        const propNameLowerCase = prop.toLowerCase();
        if (propNameLowerCase.includes('type')) {
          return ` [type: ${object[prop]}]`;
        }
      }
    }
  }
  return '';
};

const concatObjectCodeIfAny = (object) => {
  if (object !== undefined && object.code) {
    return ` [name:${concatObjectPath(object.code)}]`;
  }
  return '';
};

const concatObjectPath = (path) => {
  const objectDelimeter = ' ';

  return `${objectDelimeter}${path}`;
};


if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = configUpgradeParser;
} else {
  window.configUpgradeParser = configUpgradeParser;
}
