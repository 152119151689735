<app-grid-header [header]="customerName + ' ->' + targetVersion" [navigationPath]="'Comparison / ' + buildId">
</app-grid-header>
<p-card header="Upgrade Report" [subheader]="reportDate">
    <p class="m-0">
        <p-splitter [panelSizes]="[60, 40]" [style]="{ height: '100%' }" styleClass="mb-5">
            <ng-template pTemplate>
                <p-table [value]="this.resultItems" [tableStyle]="{ 'min-width': '30rem' }"  styleClass="p-datatable-sm" selectionMode="single" [(selection)]="selectedItem" dataKey="path">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:10%">
                                <p-columnFilter field="status" matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" field="status" placeholder="Action">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [options]="statuses" (onChange)="filter($event.value)" placeholder="Any" [showClear]="true">
                                            <ng-template let-option pTemplate="item">
                                                <p-tag [value]="option.value" [severity]="getStatusTagSeverityForItem(option.value)"></p-tag>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </th>
                            <th style="width:90%">
                                <p-columnFilter type="text" matchMode="contains" field="path" placeholder="Search path"></p-columnFilter>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr [pSelectableRow]="item">
                            <td><p-tag [rounded]="true" [icon]="'pi pi-' + getStatusIconValueForItem(item.status)" [severity]="getStatusTagSeverityForItem(item.status)" value="{{ item.status | titlecase }}"></p-tag></td>
                            <td>{{ item.path }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
            <ng-template pTemplate>
                <p-card header="Item Explorer" class="w-full">
                    <nxt-json-view [data]="this.selectedItem" [levelOpen]="this.reportLevelOpen"></nxt-json-view>
                </p-card>
            </ng-template>
        </p-splitter>
        
        
    </p>
</p-card>
