<p-dialog [(visible)]="visible" [modal]="true" (onHide)="hide()" styleClass="w-4">
  <ng-template pTemplate="header">
    <span class="font-semibold"> {{ header }} </span>
  </ng-template>
  <div class="mt-3">
    {{ message }}
  </div>
  <ng-template pTemplate="footer">
    <div class="mt-4">
      <p-button (click)="okBtnClicked()" label="OK"></p-button>
    </div>
  </ng-template>
</p-dialog>
