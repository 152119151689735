<p-button (click)="showCreateNewComparisonDialog()" label="Run New Comparison"></p-button>
<form (ngSubmit)="onSubmit()" [formGroup]="comparisonForm">
    <p-dialog header="New Comparison" [(visible)]="visible" (onHide)="onHide()" [modal]="true" styleClass="w-4">
        <ng-template pTemplate="header">
            <span class="font-semibold">New comparison</span>
        </ng-template>

        <div class="form-group mt-3" *ngIf="visible">
            <div class="flex flex-column gap-2 py-2">
                <label for="customer" class="">Customer Name <i pTooltip="Customers listed are sourced from ADO Artifacts. If not found, their configurations will have to be exported from the ADO pipeline 'Config Export'." class="fa-solid fa-circle-info"></i></label>
                <p-dropdown (ngModelChange)="onCustomerSelection($event)" id="customer" name="customer" appendTo="body"
                    formControlName="customer" [options]="customers" optionLabel="normalizedName"
                    optionValue="normalizedName" [showClear]="true" placeholder="Select a Customer"
                    [style]="{ minWidth: '100%' }" class="block w-full form-control"></p-dropdown>

                <div [hidden]="comparisonForm.controls['customer'].valid || !comparisonForm.controls['customer'].dirty"
                    class="alert alert-danger font-normal">
                    Customer name is required
                </div>
            </div>
        </div>

        <div class="form-group mt-3" *ngIf="visible">
            <div class="flex flex-column gap-2 py-2">
                <label for="customerConfigVersion" class="">Customer Configuration</label>
                <p-dropdown id="customerConfigVersion" name="customerConfigVersion" appendTo="body" formControlName="customerConfigVersion"
                    [options]="customerConfigVersions" optionLabel="normalizedVersion" optionValue="normalizedVersion"
                    [showClear]="true" placeholder="Select a Customer Configuration" [style]="{ minWidth: '100%' }"
                    class="block w-full form-control"></p-dropdown>

                <div [hidden]="comparisonForm.controls['customerConfigVersion'].valid || !comparisonForm.controls['customerConfigVersion'].dirty"
                    class="alert alert-danger font-normal">
                    Customer Configuration is required
                </div>
            </div>
        </div>

        <div class="form-group mt-3" *ngIf="visible">
            <div class="flex flex-column gap-2 py-2">
                <label for="targetVersion" class="">Target Versions <i pTooltip="The list of available target versions is sourced from the releasedconfiguration templates in ADO artifacts." class="fa-solid fa-circle-info"></i></label>
                <p-dropdown id="targetVersion" name="targetVersion" appendTo="body" formControlName="targetVersion"
                    [options]="targetVersions" optionLabel="version" optionValue="normalizedVersion" [showClear]="true"
                    placeholder="Select a Target Version" [style]="{ minWidth: '100%' }"
                    class="block w-full form-control"></p-dropdown>

                <div [hidden]="comparisonForm.controls['targetVersion'].valid || !comparisonForm.controls['targetVersion'].dirty"
                    class="alert alert-danger font-normal">
                    Target Version is required
                </div>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <div class="mt-4">
                <p-button label="Cancel" (click)="hide()" styleClass="p-button-outlined p-button-secondary"></p-button>
                <p-button type="submit" label="Run" [disabled]="!canSubmit()"></p-button>
            </div>
        </ng-template>
    </p-dialog>
</form>