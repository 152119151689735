import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { VersionPipe } from '../../shared/pipes/version.pipe';
import { environment } from 'src/environments/environment';

interface PackageVersion {
  id: string;
  version: string;
  normalizedVersion: string;
  publishDate: string;
}
interface Package {
  id: string;
  normalizedName: string;
  versions: PackageVersion[];
}
interface Feed {
  count: number;
  value: Package[];
}

@Component({
  selector: 'app-new-comparison',
  templateUrl: './new-comparison.component.html',
  styleUrls: ['./new-comparison.component.css']
})
export class NewComparisonComponent implements OnInit {
  visible = false;
  feed: Feed | undefined;
  customers!: Package[];
  customerConfigVersions: PackageVersion[] | undefined = [];
  targetVersions!: PackageVersion[] | undefined;
  comparisonForm!: FormGroup;

  @Output() reloadGrid: EventEmitter<void> = new EventEmitter<void>();

  constructor(private http: HttpClient, private versionPipe: VersionPipe) {}

  ngOnInit(): void {
    this.comparisonForm = new FormGroup({
      customer: new FormControl('', [Validators.required]),
      customerConfigVersion: new FormControl('', [Validators.required]),
      targetVersion: new FormControl('', [Validators.required]),
    });
    this.fetchCustomers(`${environment.configPackagingApiConfig.uri}/config-xmls/packages?api-version=7.1-preview.1&includeAllVersions=true`);
  }

  async runComparison() {
    const customerName = this.comparisonForm.controls['customer'].value;
    const customerConfigVersion = this.comparisonForm.controls['customerConfigVersion'].value;
    const targetVersion = this.comparisonForm.controls['targetVersion'].value;
    await this.buildPipeline(`${environment.buildApiConfig.uri}/?api-version=7.1-preview.7`, customerName, customerConfigVersion, targetVersion);
  }

  fetchCustomers(url: string) {
    this.http.get<Feed>(url)
      .subscribe(feed => {
        this.customers = feed.value.filter(customer => customer.normalizedName !== 'templates');
        this.targetVersions = feed.value?.find(customer => customer.normalizedName === 'templates')?.versions?.map(packageVersion => {
          return {
            ...packageVersion,
            version: packageVersion.version
          };
        });
      });
  }
  onCustomerSelection(customerName: string){
    this.customerConfigVersions = this.customers.find(customer => customer.normalizedName === customerName)?.versions?.map(packageVersion => {
      return {
        ...packageVersion,
        version: this.versionPipe.transform(packageVersion.version)
      };
    });
  }

  async buildPipeline(url: string, customerName: string, customerConfigVersion:string, targetVersion: string) {
    const comparisonAdoPipelineId = 75;
    await this.http.post(url, 
      { 
        definition: {
          id: comparisonAdoPipelineId
        },
        templateParameters: {
          customerName: customerName,
          customerConfigVersion: customerConfigVersion,
          targetVersion: targetVersion
        }
      }).subscribe(res => console.log({res}))
  }

  showCreateNewComparisonDialog(): void {
    this.visible = true;
  }

  async onSubmit() {
    await this.runComparison();
    this.visible = false;
    this.reloadGrid.emit();
  }

  canSubmit(): boolean {
    return (
      this.comparisonForm.controls['customer'].value != undefined &&
      this.comparisonForm.controls['targetVersion'].value != undefined
    );
  }

  hide(): void {
    this.visible = false;
  }

  onHide(): void {
    this.comparisonForm.reset();
    this.comparisonForm.markAsPristine();
  }
}

