<div class="card p-3 mx-3" *ngIf="!isLoading">
  <div class="flex my-3" *ngIf="title" style="overflow-wrap: anywhere">
    <div class="col align-items-left flex-grow-1 p-0 mr-4">
      <div class="text-3xl pb-1 font-semibold">{{ title }} <i class="mx-2 fa-2xs fa-regular fa-pen-to-square"></i></div>
      <div class="text-xs font-semibold">{{ subtitle }}</div>
    </div>
    <ng-content></ng-content>
  </div>
  <p-table
    #dt2
    styleClass="p-datatable-sm"
    [value]="data"
    dataKey="id"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [paginator]="true"
    [tableStyle]="{ 'min-width': '75rem' }"
    (onRowSelect)="onRowSelect()"
    [(selection)]="selectedData"
    selectionMode="single"
    [sortField]="defaultSortField"
    [sortOrder]="defaultSortOrder"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let col of columns; index as i"
          [pSortableColumn]="col.value"
          colspan="col.colspan"
          [ngClass]="
            i === 0 ? 'border-round-left-no-bottom' : i === columns.length - 1 ? 'border-round-right-no-bottom' : ''
          "
          [ngStyle]="{ width: col.width }"
        >
          {{ col.header }}
          <p-sortIcon [field]="col.value"></p-sortIcon>
        </th>
        <th *ngIf="hasActions"></th>
      </tr>
      <tr>
        <th *ngFor="let col of columns">
          <p-columnFilter
            type="date"
            field="{{ col.value }}"
            *ngIf="col.type === 'date'"
            display="menu"
            [ngStyle]="{ width: col.width }"
          ></p-columnFilter>
          <p-columnFilter
            type="numeric"
            field="{{ col.value }}"
            *ngIf="col.type === 'numeric'"
            [ngStyle]="{ width: col.width }"
          ></p-columnFilter>
          <p-columnFilter
            type="boolean"
            field="{{ col.value }}"
            *ngIf="col.type === 'boolean'"
            [ngStyle]="{ width: col.width }"
            [showClearButton]="false"
          ></p-columnFilter>
          <p-columnFilter
            matchMode="contains"
            type="text"
            field="{{ col.value }}"
            *ngIf="!col.type || col.type === 'text' || col.type === 'phoneNumber'"
            [ngStyle]="{ width: col.width }"
            [attr.data-testid]="'filter-text-' + col.value"
          ></p-columnFilter>
          <p-columnFilter
            [matchModeOptions]="matchModeOptionsStringArray"
            type="text"
            field="{{ col.value }}"
            *ngIf="col.type === 'string-array'"
            [ngStyle]="{ width: col.width }"
            [attr.data-testid]="'filter-text-' + col.value"
          ></p-columnFilter>
          <p-columnFilter
            field="{{ col.value }}"
            matchMode="equals"
            [showMenu]="false"
            [showClearButton]="true"
            [ngStyle]="{ width: col.width }"
            *ngIf="col.type === 'formQuestionType'"
          >
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">

            </ng-template>
          </p-columnFilter>
        </th>
        <th *ngIf="hasActions"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr [pSelectableRow]="data">
        <td *ngFor="let col of columns" class="datatable-td" [ngStyle]="{ width: col.width }">
          <ng-template #noTag>
            <div *ngIf="col.detailsOp && data[col.value] && data[col.value].length" class="flex">
              <div class="col-details-op-text">
                {{ transformData(col, data) }}
              </div>
              <div class="col-details-op-button">
                <p-button
                  icon="pi pi-angle-down"
                  iconPos="right"
                  styleClass="p-button-rounded white-space-nowrap w-5rem"
                  (click)="col_details_op.toggle($event); onSelectCellForDetailsOverlay(col, data)"
                  label="{{ getColDetailsOpTagDisplayText(col, data) }}"
                ></p-button>
              </div>
            </div>

            <div *ngIf="!col.detailsOp">
              {{ transformData(col, data) }}
            </div>
          </ng-template>

          <p-tag
            *ngIf="col.tag; else noTag"
            severity="{{ getSeverity(data, col.value) }}"
            value="{{ getSeverityText(data, col.value) }}"
            [rounded]="true"
            [style.display]="'grid'"
          ></p-tag>
        </td>
        <td *ngIf="hasActions">
          <p-button
            (click)="onSelectRowForAction({ rowData: data, actions: actions }); actionMenu.toggle($event)"
            styleClass="p-button-link p-0"
            icon="pi pi-ellipsis-v"
          ></p-button>
          <p-menu #actionMenu appendTo="body" [model]="actions" [popup]="true" class="actionMenu"></p-menu>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="12">No rows found.</td>
      </tr>
    </ng-template>
  </p-table>
  <p-overlayPanel
    #col_details_op
    [showCloseIcon]="true"
    (onShow)="onShowCellDetailsOverlay()"
    [styleClass]="'columnDetailsContent'"
    ><div id="col_details_content"></div
  ></p-overlayPanel>
</div>
