import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.css'],
})
export class GridHeaderComponent {
  @Input() header = '';
  @Input() navigationPath = '';
}
