<app-grid-header header="Comparisons" navigationPath="Comparison /">
    <app-new-comparison (reloadGrid)="reloadGridData()"
        class="flex justify-content-end"></app-new-comparison>
</app-grid-header>
<app-grid 
  rowSelectionBasePath="comparison"
  [data]="comparisons" 
  [isLoading]="isLoading" 
  [columns]="gridColumns" 
  (selectRowForAction)="onSelectRowForAction($event)">
</app-grid>