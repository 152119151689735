import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css'],
})
export class ConfirmationModalComponent {
  @Input() visible!: boolean;
  @Input() confirmationMessage!: string;
  @Input() confirmationHeaderText = 'Confirm action';
  @Input() confirmationPrimaryButtonText = 'Yes';
  @Output() confirmAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() hideEvent: EventEmitter<void> = new EventEmitter<void>();

  confirm(): void {
    this.confirmAction.emit();
  }

  hide(): void {
    this.hideEvent.emit();
  }
}
