export const environment = {
    production: false,
    msalConfig: {
        auth: {
            clientId: '0532c80a-00a1-43a3-86b1-e8a4d62bf8a5',
            authority: 'https://login.microsoftonline.com/c8d9e9ff-ccc6-4692-acae-5a72a27a98b5'
        }
    },
    graphApiConfig: {
        scopes: ['user.read'],
        uri: 'https://graph.microsoft.com/v1.0/me'
    },
    configPackagingApiConfig: {
        scopes: ['499b84ac-1321-427f-aa17-267ca6975798/vso.packaging'],
        uri: 'https://feeds.dev.azure.com/SoftCoDevOps/SoftCo10/_apis/packaging/Feeds'
    },
    buildApiConfig: {
        scopes: ['499b84ac-1321-427f-aa17-267ca6975798/vso.build_execute'],
        uri: 'https://dev.azure.com/SoftCoDevOps/SoftCo10/_apis/build/builds'
    },
    resourceApiConfig: {
        scopes: ['499b84ac-1321-427f-aa17-267ca6975798/vso.packaging'],
        uri: 'https://dev.azure.com/SoftCoDevOps/_apis/resources'
    }
};
