import { Component, Input, Renderer2, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FilterMatchMode, FilterService, MenuItem, SelectItem } from 'primeng/api';
import { VersionPipe } from '../pipes/version.pipe';

@Component({
  selector: 'app-grid',
  templateUrl: 'grid.component.html',
  styleUrls: ['grid.component.css'],
})
export class GridComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() columns!: any[];
  @Input() rowSelectionBasePath!: string;
  @Input() isLoading!: boolean;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() actions!: MenuItem[] | undefined;
  @Input() defaultSortField!: string;
  @Input() defaultSortOrder = 1;
  @Output() selectRowForAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectCellForDetailsOverlay: EventEmitter<any> = new EventEmitter<any>();
  selectedCellData!: object;

  hasActions = false;

  selectedData!: any;

  matchModeOptionsStringArray: SelectItem[] = [
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Not contains', value: FilterMatchMode.NOT_CONTAINS },
    { label: 'Equals', value: 'string-array-item-equals' },
    { label: 'Not equals', value: 'string-array-item-not-equals' },
  ];

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router,
    private datePipe: DatePipe,
    private versionPipe: VersionPipe,
    private readonly filterService: FilterService
  ) {}
  async ngOnInit() {
    this.hasActions = this.actions! && this.actions.filter((action) => action.visible).length > 0;
    this.initCustomFilters();
  }

  clear(table: Table) {
    table.clear();
  }

  ngAfterViewInit(): void {
    // Attach the click event handler to the p-dropdown clear button
    const clearButtons = this.el.nativeElement.querySelectorAll('button.p-column-filter-clear-button');
    for (let i = 0; i < clearButtons.length; i++) {
      if (clearButtons[i].parentElement.querySelector('p-dropdown')) {
        this.renderer.listen(clearButtons[i], 'click', () => {
          if (clearButtons[i].parentElement.parentElement.querySelector('timesicon')) {
            clearButtons[i].parentElement.parentElement.querySelector('timesicon').dispatchEvent(new Event('click'));
          }
        });
      }
    }
  }

  transformData(col: any, data: any): any {
    if (col.type === 'date') {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return this.datePipe.transform(data[col.value], col.dateFormat, timeZone);
    } else if (col.type === 'version') {
      return this.versionPipe.transform(data[col.value]);
    }

    const value = data[col.value];
    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return data[col.value];
  }

  async initCustomFilters() {
    this.filterService.register('string-array-item-equals', (values: any[], filter: string) => {
      if (filter === undefined || filter === null || filter.length === 0) {
        return true;
      }

      if (values === undefined || values === null) {
        return false;
      }

      let match = false;
      for (const v of values) {
        if (v !== undefined && v !== null) {
          const filterUpper = filter.toUpperCase();
          const upper = v.toUpperCase();
          if (upper === filterUpper) {
            match = true;
            break;
          }
        }
      }
      return match;
    });

    this.filterService.register('string-array-item-not-equals', (values: any[], filter: string) => {
      if (filter === undefined || filter === null || filter.length === 0) {
        return false;
      }

      if (values === undefined || values === null) {
        return true;
      }

      let match = true;
      for (const v of values) {
        if (v !== undefined && v !== null) {
          const filterUpper = filter.toUpperCase();
          const upper = v.toUpperCase();
          if (upper === filterUpper) {
            match = false;
            break;
          }
        }
      }
      return match;
    });
  }

  getSeverity(row: any, field: string) {
    const status = row[field];
    switch (status) {
      case 'failed':
        return 'danger';
      case 'succeeded':
        return 'success';
      default:
        return 'primary';
    }
  }

  getSeverityText(row: any, field: string) {
    const status = row[field];
    switch (status) {
      case 'failed':
        return '❌ Failed';
      case 'succeeded':
        return '✔️ Succeeded';
      default:
        return '⏳ In Progress';
    }
  }

  onSelectRowForAction(rowDataAndCurrentActions: any) {
    this.selectRowForAction.emit(rowDataAndCurrentActions);
  }

  onRowSelect() {
    this.router.navigate([`/${this.rowSelectionBasePath}/${this.selectedData.id}`]);
    this.selectRow.emit(this.selectedData.id);
  }

  onSelectCellForDetailsOverlay(col: any, data: any) {
    this.selectedCellData = { col: col, data: data };
  }

  onShowCellDetailsOverlay() {
    this.selectCellForDetailsOverlay.emit(this.selectedCellData);
  }

  getColDetailsOpTagDisplayText(col: any, data: any): string {
    if (col.value === 'assignedTo') {
      if (data.status === 'Initial Approval' || data.status === 'Final Approval') {
        const stage = data.status === 'Initial Approval' ? 'Initial' : 'Final';
        return (
          data['approvals'].filter((x: any) => x.stage === stage && x.status == 'Approved').length +
          ' / ' +
          data[col.value].length
        );
      }
      return data[col.value].length;
    } else {
      return data[col.value].length;
    }
  }
}
