import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-acknowledge-modal',
  templateUrl: './acknowledge-modal.component.html',
  styleUrls: ['./acknowledge-modal.component.css'],
})
export class AcknowledgeModalComponent {
  @Input() visible!: boolean;
  @Input() message!: string;
  @Input() header!: string;
  @Output() okAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() hideEvent: EventEmitter<void> = new EventEmitter<void>();

  okBtnClicked(): void {
    this.okAction.emit();
  }

  hide(): void {
    this.hideEvent.emit();
  }
}
