<p-dialog [(visible)]="visible" [modal]="true" styleClass="w-4">
  <ng-template pTemplate="header">
    <span class="font-semibold"> {{ confirmationHeaderText }} </span>
  </ng-template>
  <div class="mt-3 text-break-word">
    {{ confirmationMessage }}
  </div>
  <ng-template pTemplate="footer">
    <div class="mt-4">
      <p-button label="Cancel" (click)="hide()" styleClass="p-button-outlined p-button-secondary"></p-button>
      <p-button (click)="confirm()" label="{{ confirmationPrimaryButtonText }}"></p-button>
    </div>
  </ng-template>
</p-dialog>
