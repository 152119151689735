<p-menubar styleClass="top-0 z-5 sticky border-none border-noround shadow-2 p-0 h-70px">
  <ng-template pTemplate="start">
    <div class="flex align-items-center h-70px">
      <div class="p-4 border-right-1 border-gray-300 h-full">
        <app-logo></app-logo>
      </div>
      <div class="p-3 font-italic text-lg">{{ title }}</div>
    </div>
  </ng-template>
  <ng-template pTemplate="end">
    <div class="flex align-items-center h-70px">
      <p-button class="pr-5" *ngIf="!loginDisplay" (click)="loginPopup()">Login</p-button>
      <div *ngIf="loginDisplay" class="flex align-items-center border-left-1 border-gray-300 h-full">
        <p-avatar icon="pi pi-user" class="mx-3" size="large" shape="circle"></p-avatar>
        <div class="pb1">
          <div class="text-lg pb-1 font-semibold">{{ name | titlecase }}</div>
          <div class="text-sm text-500">{{ username | titlecase }}</div>
        </div>
        <div class="ml-7 mr-3 pb-2">
          <p-button
            (click)="profileMenu.toggle($event)"
            styleClass="p-button-link"
            icon="font-bold text-xs pi pi-chevron-down"
          ></p-button>
          <p-menu #profileMenu [model]="profileMenuItems" styleClass="profileMenu" [popup]="true"></p-menu>
        </div>
      </div>
    </div>
  </ng-template>
</p-menubar>
<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>