import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { VersionPipe } from '../../shared/pipes/version.pipe'
import { environment } from 'src/environments/environment';
import { LevelLabels } from 'nxt-json-view'

const ConfigUpgradeParser = require('../../../../config-report-parser-lib/index');

interface Artifact {
  id: string,
  resource: {
    data: string,
    downloadUrl: string
  }
}

interface Build {
  id: string;
  templateParameters: {
    customerName: string,
    targetVersion: string
  };
  startTime: string;
  status: string;
  result: string;
  requestedBy: {
    displayName: string;
  };
  url: string;
}

@Component({
  selector: 'app-comparison-report',
  templateUrl: './comparison-report.component.html',
  styleUrls: ['./comparison-report.component.css']
})
export class ComparisonReportComponent implements OnInit {
  reportJson!: string;
  reportLevelOpen = 10;
  buildId!: string;
  customerName!: string;
  targetVersion!: string;
  reportDate!: string;
  result!: any;
  resultItems!: any;
  selectedItem!: any;
  statuses!: any[];
  constructor(private route: ActivatedRoute, private http: HttpClient, private datePipe: DatePipe, private versionPipe: VersionPipe) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.buildId = params.get('buildId')!
      this.fetchComparisonReport(this.buildId);
      this.fetchComparisonMetadata(this.buildId);
    });
    this.statuses = [
      { label: 'Added', value: 'added' },
      { label: 'Removed', value: 'removed' },
      { label: 'Updated', value: 'updated' }
  ];
  }

  fetchComparisonMetadata(buildId: string) {
    const buildMetadataUri = `${environment.buildApiConfig.uri}/${buildId}?api-version=7.1-preview.7`;
    this.http.get<Build>(buildMetadataUri).subscribe(metadata => {
      this.customerName = metadata.templateParameters.customerName;
      this.targetVersion = this.versionPipe.transform(metadata.templateParameters.targetVersion);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const startTime = this.datePipe.transform(metadata?.startTime, 'M/d/yy, h:mm a', timeZone);
      this.reportDate = startTime ? startTime : '';
    });
  }
  fetchComparisonReport(buildId: string) {
    const buildArtifactsUri = `${environment.buildApiConfig.uri}/${buildId}/artifacts?artifactName=Comparison&api-version=7.1-preview.5`;
    this.http.get<Artifact>(buildArtifactsUri)
      .subscribe(artifact => {
        const artifactContainer = artifact.resource.data.split('/')[1]; // #/67675051/Comparison -> 67675051/Comparison
        this.downloadArtifact(artifactContainer);
      });
  }

  async downloadArtifact(artifactContainer: string) {
    const reportDownloadUrl = `${environment.resourceApiConfig.uri}/Containers/${artifactContainer}?itemPath=Comparison%2Freport.json`
    this.http.get(reportDownloadUrl, {
      headers: new HttpHeaders({ 'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8' })
    })
      .subscribe((reportJson: any) => {
        this.reportJson = reportJson;
        this.result = JSON.parse(ConfigUpgradeParser.parse(JSON.stringify(reportJson)));
        this.resultItems = this.result.added.map((item: { path: string }) => ({ path: item.path, status: 'added' }))
          .concat(this.result.removed.map((item: { path: string }) => ({ path: item.path, status: 'removed' })))
          .concat(this.result.updated.map((item: { path: string, differences: string }) => ({
            path: item.path, status: 'updated', changes:
            {
              "changes": item.differences
            }
          })));

      });
  }

  getStatusTagSeverityForItem(status: string) {
    switch (status) {
      case 'added':
        return 'success';
      case 'removed':
        return 'danger';
      case 'updated':
        return 'warning';
      default:
        return 'primary';
    }
  }

  getStatusIconValueForItem(status: string) {
    switch (status) {
      case 'added':
        return 'plus';
      case 'removed':
        return 'minus';
      case 'updated':
        return 'pencil';
      default:
        return 'question';
    }
  }

}
