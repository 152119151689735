import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';

interface Comparison {
  id: string;
  customer: string;
  targetVersion: string;
  status: string;
  result: string;
  requestedBy: string;
  startTime: string;
  pipelineUrl: string;
}
interface Build {
  id: string;
  templateParameters: {
    customerName: string,
    customerConfigVersion: string,
    targetVersion: string
  };
  startTime: string;
  status: string;
  result: string;
  requestedBy: {
    displayName: string;
    imageUrl: string;
  };
  url: string;
}
interface Builds {
  count: number;
  value: Build[];
}
@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.css']
})
export class ComparisonComponent implements OnInit {
  gridColumns: any[] = [];
  selectedRowId!: string;
  isLoading = false;
  actionItems!: MenuItem[];
  showConfirmationDialog = false;
  confirmationMessage!: string;
  confirmationAction!: any;
  comparisons: Comparison[] = [];
  comparisonPipelineId = 75;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.gridColumns = [
      { value: 'customer', header: 'Customer name', width: '15%' },
      { value: 'customerVersion', header: 'Source Version', width: '15%', type: 'version' },
      { value: 'targetVersion', header: 'Target Version', width: '15%', type: 'version' },
      { value: 'result', header: 'Result', width: '10%', tag: true },
      { value: 'requestedBy', header: 'Requested By', width: '15%' },
      { value: 'startTime', header: 'Start Time', width: '30%', type: 'date', dateFormat: 'M/d/yy, h:mm a' },
    ];
    this.reloadGridData();
  }

  reloadGridData() {
    this.fetchComparisons(`${environment.buildApiConfig.uri}?definitions=${this.comparisonPipelineId}&api-version=7.1-preview.7`);
  }

  fetchComparisons(url: string) {
    this.http.get<Builds>(url)
      .subscribe(builds => {
        this.comparisons = builds.value.map(build => ({
          id: build.id,
          customer: build.templateParameters?.customerName || 'N/A',
          customerVersion: build.templateParameters?.customerConfigVersion || 'N/A',
          targetVersion: build.templateParameters?.targetVersion || 'N/A',
          status: build.status,
          result: build.result,
          requestedBy: build.requestedBy.displayName,
          startTime: build.startTime,
          pipelineUrl: build.url
        }));
      });
  }

  onSelectRowForAction(rowDataAndCurrentActions: any) {
    this.selectedRowId = rowDataAndCurrentActions.rowData.id;
  }

  showConfirmationMessage(message: string) {
    this.confirmationMessage = message;
    this.showConfirmationDialog = true;
  }

  hideConfirmationDialog() {
    this.showConfirmationDialog = false;
  }
}
